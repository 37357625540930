<template>
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <div class="page-container-table">
      <div class="list-operation">
        <el-button type="primary" size="small" @click="handleAdd">
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #proCate="{ row }">
          {{ $code("proCate", row.proCate) }}
        </template>
        <template #proType="{ row }">
          {{ $childrenCode("proCate",row.proCate,row.proType) }}
        </template>
        <template #proFundRes="{ row }">
          {{ $code("proFundRes", row.proFundRes) }}
        </template>
        <template #proCheckFlag="{ row }">
          {{ $code("proCheckFlag",row.proCheckFlag) }}
        </template>
        <template #proState="{ row }">
          {{ $code("proState", row.proState) }}
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
  </div>
</template>
<script>
import { productManageList, stopProduct, startProduct } from '@/api/tradeFinancing.js'
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        proName: '', // 产品名称
        proCate: '', // 产品类别
        proType: '', // 产品品种
        coreEpId: '', // 核心企业id
        proFundRes: '', // 授信来源
        proFundId: '', // 资金方
        proState: '' // 产品状态
      },
      formItemArr: [
        { type: 'input', label: '产品名称', value: 'proName' },
        { type: 'select', label: '产品类别', value: 'proCate', pLabel: 'text', pValue: 'value', child: this.$select('proCate') }, // this.$childrenSelect('proCate', this.formInline.proCate)
        { type: 'select', label: '产品品种', value: 'proType', pLabel: 'text', pValue: 'value', child: [] },
        { type: 'select', label: '核心企业', value: 'coreEpId', pLabel: 'cmpName', pValue: 'cmpCode', child: [] },
        { type: 'select', label: '授信来源', value: 'proFundRes', pLabel: 'text', pValue: 'value', child: this.$select('proFundRes') },
        { type: 'select', label: '资金方', value: 'proFundId', pLabel: 'funderName', pValue: 'id', child: [] },
        { type: 'select', label: '产品状态', value: 'proState', pLabel: 'text', pValue: 'value', child: this.$select('proFundRes') }
      ],
      itemData: [
        { label: '产品编号', prop: 'proId', width: 120 },
        { label: '资金方产品编号', prop: 'proFundNo', width: 120 },
        { label: '资金方业务主体编号', prop: 'proFundMainNo', width: 150 },
        { label: '产品名称', prop: 'proName', width: 150 },
        { label: '产品类别', prop: 'proCate', width: 150 },
        { label: '产品品种', prop: 'proType', width: 150 },
        { label: '集团名称', prop: 'groupCmpName', width: 150 },
        { label: '授信来源', prop: 'proFundRes', width: 150 },
        { label: '核心企业', prop: 'coreEpName', width: 150 },
        { label: '资金方', prop: 'proFundName', width: 150 },
        { label: '审核状态', prop: 'proCheckFlag', width: 150 },
        { label: '产品状态', prop: 'proState', width: 150 }
      ],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails },
        { bType: 'primary', label: '编辑', handleEvent: this.editProduct, child: [{ val: 'editButton' }] },
        { bType: 'primary', label: '审核', handleEvent: this.handleAudit, child: [{ val: 'auditButton' }] },
        { bType: 'primary', label: '停用', handleEvent: this.handleEnable, child: [{ val: 'stopButton' }] },
        { bType: 'primary', label: '启用', handleEvent: this.handleEnable, child: [{ val: 'startButton' }] }
        // { num: 1, val: 'cooStatus', bType: 'danger', label: '终止', handleEvent: this.modifyState },
        // { num: 0, val: 'cooStatus', bType: 'primary', label: '恢复', handleEvent: this.modifyState },
        // { num: 0, val: 'cooStatus', bType: 'danger', label: '删除', handleEvent: this.deleteRow }
      ]

    }
  },
  computed: {
    coreEnterPriseList() {
      return this.$store.state.tradeFinancing.coreEnterPriseList
    },
    bankList() {
      return this.$store.state.tradeFinancing.bankList
    }
  },
  watch: {
    'formInline.proCate': {
      handler(newV) {
        this.formInline.proType = ''
        this.formItemArr.map(item => {
          if (item.value === 'proType') {
            item.child = this.$childrenSelect('proCate', newV)
          }
        })
      }
    }
  },
  async created() {
    if (!this.$store.state.tradeFinancing.coreEnterPriseList.length) {
      await this.$store.dispatch('tradeFinancing/queryCmpListByCmpRoleList')
    }
    if (!this.$store.state.tradeFinancing.bankList.length) {
      await this.$store.dispatch('tradeFinancing/querBankList')
      this.formItemArr.map(item => {
        if (item.label === '核心企业') {
          item.child = this.coreEnterPriseList
        }
        if (item.label === '资金方') {
          item.child = this.bankList
        }
      })
    }
  },
  methods: {
    getDate(params) {
      this.loading = true
      this.formInline = {
        ...this.formInline,
        ...params
      }
      productManageList(this.formInline, res => {
        this.loading = false
        this.listData = res.data.pageData
        this.listData.map(item => {
        // 控制编辑按钮   在审核中和禁用下不能有编辑按钮
          if ((item.proCheckFlag === '01' && item.proState === '01') || item.proState === '04') {
            item.editButton = true
          }
          if ((item.proCheckFlag === '02' || item.proCheckFlag === '03') && (item.activiteId && item.currentNodeId)) {
            item.auditButton = true
          }
          if (item.proCheckFlag === '04' && item.proState === '02') {
            item.stopButton = true
          } else {
            item.startButton = true
          }
        })
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    editProduct(row) {
      this.$router.push({
        name: 'productEdit',
        query: {
          id: row.id,
          proCheckFlag: row.proCheckFlag // =='01'待提交的有保存按钮，其他情况都没有保存按钮
        }
      })
    },
    handleAdd({ id, proCheckFlag }) {
      this.$router.push({
        path: '/productManage/addProduct',
        query: { id, proCheckFlag }
      })
    },
    viewDetails(row) {
      this.$router.push({
        path: '/productManage/productDetail',
        query: {
          type: '1',
          proId: row.proId,
          id: row.id,
          activiteId: row.activiteId,
          currentNodeId: row.currentNodeId,
          proCheckFlag: row.proCheckFlag // 审核状态
        }
      })
    },
    // 审核
    handleAudit(row) {
      this.$router.push({
        path: '/productManage/productAudit',
        name: 'productAudit',
        query: {
          type: '2',
          id: row.id,
          proId: row.proId,
          activiteId: row.activiteId,
          currentNodeId: row.currentNodeId,
          proCheckFlag: row.proCheckFlag, // 审核状态
          proCate: row.proCate,
          proType: row.proType,
          proFundId: row.proFundId // 资金方id
        }
      })
    },
    // 启用-停用
    handleEnable(row) {
      const stat = row.proState === '02' ? '停用' : '启用'
      this.$confirm(`您确定要${stat}当前产品吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (row.proState === '02') {
          // 已启用状态 去停用
            stopProduct({ id: row.id }, res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '停用成功'
                })
                this.getDate()
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
            })
          } else {
            startProduct({ id: row.id }, res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '停用成功'
                })
                this.getDate()
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>
